import React, { useState } from "react";
import "./styles.css";

const chunkImages = (images, size) => {
  const chunks = [];
  for (let i = 0; i < images.length; i += size) {
    chunks.push(images.slice(i, i + size));
  }

  return chunks;
};

const GalleryModal = ({ isOpen, onClose, images }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const imagesPerPage = isMobile ? 1 : 2;
  const imageChunks = chunkImages(images, imagesPerPage);

  if (!isOpen) return null;

  const handlePrev = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? imageChunks.length - 1 : prevPage - 1
    );
  };

  const handleNext = () => {
    setCurrentPage((prevPage) =>
      prevPage === imageChunks.length - 1 ? 0 : prevPage + 1
    );
  };

  return (
    <section id="modal">
      <span className="close" onClick={onClose}>
        &times;
      </span>
      <div className="modal-content">
        <div className="image-grid">
          {imageChunks[currentPage].map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Gallery ${currentPage * imagesPerPage + index + 1}`}
            />
          ))}
        </div>
        <div className="navigation">
          <button onClick={handlePrev}>&lt;</button>
          <button onClick={handleNext}>&gt;</button>
        </div>
      </div>
    </section>
  );
};

export default GalleryModal;
