import { useState } from "react";
import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import Carousel from "../../components/Carousel";
import FormasPagamento from "../../components/FormasPagamento";
import GalleryModal from "../../components/GalleryModal";

import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import about2_5 from "../../assets/about2_5.png";
import about2_6 from "../../assets/about2_6.png";
import about2_7 from "../../assets/about2_7.png";
import about2_8 from "../../assets/about2_8.png";
import icontel2 from "../../assets/icontel2.png";
import about3_1 from "../../assets/about3_1.png";
import about3_1_1 from "../../assets/about3_1_1.png";
import about3_1_2 from "../../assets/about3_1_2.png";
import about3_1_3 from "../../assets/about3_1_3.png";
import about3_1_4 from "../../assets/about3_1_4.png";
import about3_1_5 from "../../assets/about3_1_5.png";
import about3_2 from "../../assets/about3_2.png";
import about3_2_1 from "../../assets/about3_2_1.png";
import about3_2_2 from "../../assets/about3_2_2.png";
import about3_2_3 from "../../assets/about3_2_3.png";
import about3_2_4 from "../../assets/about3_2_4.png";
import about3_2_5 from "../../assets/about3_2_5.png";
import about3_2_6 from "../../assets/about3_2_6.png";
import about3_2_7 from "../../assets/about3_2_7.png";
import about3_3 from "../../assets/about3_3.png";
import about3_3_1 from "../../assets/about3_3_1.png";
import about3_3_2 from "../../assets/about3_3_2.png";
import about3_3_3 from "../../assets/about3_3_3.png";
import about3_3_4 from "../../assets/about3_3_4.png";
import about3_3_5 from "../../assets/about3_3_5.png";
import about3_3_6 from "../../assets/about3_3_6.png";
import about3_3_7 from "../../assets/about3_3_7.png";
import about3_3_8 from "../../assets/about3_3_8.png";
import about3_4 from "../../assets/about3_4.png";
import about3_4_1 from "../../assets/about3_4_1.png";
import about3_4_2 from "../../assets/about3_4_2.png";
import about3_4_3 from "../../assets/about3_4_3.png";
import about3_4_4 from "../../assets/about3_4_4.png";
import about3_4_5 from "../../assets/about3_4_5.png";
import CtaTelWpp from "../../components/CTATelWpp";

const arr = [
  {
    image: about2_1,
  },
  {
    image: about2_2,
  },
  {
    image: about2_3,
  },
  {
    image: about2_4,
  },
  {
    image: about2_5,
  },
  {
    image: about2_6,
  },
  {
    image: about2_7,
  },
  {
    image: about2_8,
  },
];

const about3_1_imagens = [
  about3_1_1,
  about3_1_2,
  about3_1_3,
  about3_1_4,
  about3_1_5,
];

const about3_2_imagens = [
  about3_2_1,
  about3_2_2,
  about3_2_3,
  about3_2_4,
  about3_2_5,
  about3_2_6,
  about3_2_7,
];

const about3_3_imagens = [
  about3_3_1,
  about3_3_2,
  about3_3_3,
  about3_3_4,
  about3_3_5,
  about3_3_6,
  about3_3_7,
  about3_3_8,
];

const about3_4_imagens = [
  about3_4_1,
  about3_4_2,
  about3_4_3,
  about3_4_4,
  about3_4_5,
];

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  const openModal = (images) => {
    setModalImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImages([]);
  };

  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">Clínica Completa</h2>

          <p className="text">
            Nossa equipe é composta por Médico Psiquiatra, Médico Clínico Geral,
            Psicólogo, Nutricionista, Terapeuta, Conselheiros, e equipe de
            monitoria e referência em qualidade, transparência e confiança. Tudo
            isso para garantir um tratamento saudável e eficaz aos nossos
            pacientes.
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <Carousel arr={arr} isVideo={false} slidesToShow={1} />
          </div>

          <div className="right">
            <h2 className="title">Nossa Estrutura</h2>

            <div className="mobile">
              <Carousel arr={arr} isVideo={false} slidesToShow={1} />
            </div>

            <p className="text">
              Nossas instalações são projetadas para oferecer um ambiente
              confortável e adequado. Quartos amplos, banheiros, refeitório,
              enfermaria área de lazer e vivência e muito mais. Tudo isso,
              aliado a uma equipe completa e especializada de profissionais, o
              que garante bons índices de resultado.
            </p>

            <CtaWpp />
          </div>
        </div>
      </article>

      <article className="about3" id="unidades">
        <div className="container vertical">
          <h2 className="title">Nossas Unidades</h2>

          <p className="text">
            Contamos com 4 unidades na Bahia, sendo 1 feminina e 3 masculinas.
            Todas com instalações projetadas para oferecer um ambiente
            confortável e adequado. Tudo isso, aliado a uma equipe completa e
            especializada de profissionais, o que garante bons índices de
            resultado.
          </p>

          <p className="text2">
            Agende uma visita à unidade mais próxima de você!
          </p>

          <div className="gallery">
            <div className="item">
              <figure
                className="top"
                onClick={() => openModal(about3_4_imagens)}
              >
                <img src={about3_4} alt="UNIDADE MASCULINA SIMÕES FILHO (BA)" />
              </figure>

              <p className="item_title">UNIDADE MASCULINA SIMÕES FILHO (BA)</p>

              <CtaWpp />
            </div>

            <div className="item">
              <figure
                className="top"
                onClick={() => openModal(about3_1_imagens)}
              >
                <img src={about3_1} alt="UNIDADE FEMININA CAMAÇARI (BA)" />
              </figure>

              <p className="item_title">UNIDADE FEMININA CAMAÇARI (BA)</p>

              <CtaWpp />
            </div>

            <div className="item">
              <figure
                className="top"
                onClick={() => openModal(about3_2_imagens)}
              >
                <img src={about3_2} alt="UNIDADE MASCULINA CAMAÇARI (BA)" />
              </figure>
              <p className="item_title">UNIDADE MASCULINA CAMAÇARI (BA)</p>
              <CtaWpp />
            </div>

            <div className="item">
              <figure
                className="top"
                onClick={() => openModal(about3_3_imagens)}
              >
                <img src={about3_3} alt="UNIDADE MASCULINA ILHÉUS (BA)" />
              </figure>

              <p className="item_title">UNIDADE MASCULINA ILHÉUS (BA)</p>

              <CtaWpp />
            </div>
          </div>
        </div>
      </article>

      <GalleryModal
        isOpen={isModalOpen}
        onClose={closeModal}
        images={modalImages}
      />

      <article className="about4">
        <div className="container">
          <h2 className="title">
            Traga seu familiar para conhecer o caminho para uma vida renovada!
            <br />
            <span>Inscreva-o em nosso programa de tratamento.</span>
          </h2>

          <p className="text">Fale conosco por telefone ou WhatsApp:</p>

          <CtaTelWpp icon={icontel2} />
        </div>
      </article>

      <FormasPagamento />
    </section>
  );
};

export default About;
