import { useState } from "react";
import "./styles.css";

const CtaUnidades = () => {
  const [text, setText] = useState("VER UNIDADES");

  return (
    <button id="ctaUnidades">
      <a href="#unidades">
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaUnidades;
